/* You can add global styles to this file, and also import other style files */
@import "@angular/material/theming";
@import "./assets/scss/general.scss";

@include mat-core();

$primary: mat-palette($mat-blue, 100);
$accent: mat-palette($mat-grey, 900);

$theme: mat-light-theme($primary, $accent);

@include angular-material-theme($theme);

html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.main-margin {
  margin: 25px 62px;
}

.custom-border-1 {
  box-sizing: border-box;
  background: white;
  // border: 1px solid #d6d7d9;
  // box-shadow: 4px 4px 12px -4px rgba(171, 176, 186, 0.3);
  border-radius: 16px;
  border: 1px solid #dfe5ed;
  padding: 62px 32px;
}

.custom-card-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  background: white;
  border: 1px solid #dfe5ed;
  // box-shadow: 8px 8px 24px -16px rgba(171, 176, 186, 0.8);
  border-radius: 16px;
  padding: 40px 33px;
}

.hide-border {
  border: 0px !important;
}

.title {
  color: var(--primary-900, #0d3d66);
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.custom-table-border {
  display: flex;
  border: 1px solid #b2b6bc;
  padding: 0.5rem;
}

.sub-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #0d3d66;
}

.data-title {
  font-style: normal;
  font-size: 18px;
  line-height: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
}

.action-row {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 10px;
}

.website-field {
  display: flex;
  flex-grow: 1;
}

.full-width {
  width: 100%;
}

.act-btn {
  display: flex;
  padding: 8px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}

.pointer {
  cursor: pointer !important;
}

.action-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  background: #1e88e5 !important;
  color: #fafcfe !important;
  border-radius: 8px;
  cursor: pointer;
  border: none;
}

.action-btn:disabled {
  background: #d3d3d3 !important;
  cursor: not-allowed !important;
}

.no-outline-button {
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  background: none;
}

.action-btn-medium {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 10rem;
  height: 31px;
  background: #1e88e5;
  color: #fafcfe;
  border-radius: 8px;
  cursor: pointer;
}

.action-btn-outline-blue {
  display: inline-flex;
  padding: 2px 12px;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid var(--primary-700-main, #1e88e5) !important;
}

.td-btn-no-outline {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.action-btn-outline-red {
  display: inline-flex;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid var(--primary-700-main, #ed4343);
}

.action-btn-no-outline {
  display: inline-flex;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.button-text-red {
  color: var(--primary-700-main, #ed4343);
  text-align: center;
  font-size: 16px;
  font-style: normal;
}

.button-text-blue {
  color: var(--primary-700-main, #1e88e5) !important;
  text-align: center;
  font-size: 16px;
  font-style: normal;
}

.create-btn {
  height: 80px !important;
  width: 220px !important;
  background-color: #1e88e5 !important;
  //  background-color: #233240  !important;
  color: white !important;
  cursor: pointer;
}

.menu-items {
  mat-form-field {
    margin-right: 20px;
  }
}

.data-margin {
  margin: 20px;
}

.circle-button-red {
  display: flex;
  width: 0px;
  height: 29px;
  flex-shrink: 0;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 32px;
  border: 2px solid var(--error-700, #b81c1c);
  cursor: pointer;
}

.main_text {
  color: var(--primary-900, #0d3d66);
  font-size: 34px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.mini_main_text {
  color: var(--primary-900, #0d3d66);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.last-of-item {
  margin-left: auto;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.m-10 {
  margin: 4.5rem !important;
}

/* Margin left */
.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 2rem !important;
}

.ml-6 {
  margin-left: 2.5rem !important;
}

.ml-7 {
  margin-left: 3rem !important;
}

.ml-8 {
  margin-left: 3.5rem !important;
}

.ml-9 {
  margin-left: 4rem !important;
}

.ml-10 {
  margin-left: 4.5rem !important;
}

/* Margin right */
.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 2rem !important;
}

.mr-6 {
  margin-right: 2.5rem !important;
}

.mr-7 {
  margin-right: 3rem !important;
}

.mr-8 {
  margin-right: 3.5rem !important;
}

.mr-9 {
  margin-right: 4rem !important;
}

.mr-10 {
  margin-right: 4.5rem !important;
}

/* Margin left and right */
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mx-6 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.mx-7 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-8 {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}

.mx-9 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.mx-10 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}

/* Margin top */
.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-5 {
  margin-top: 2rem;
}

.mt-6 {
  margin-top: 2.5rem;
}

.mt-7 {
  margin-top: 3rem;
}

.mt-8 {
  margin-top: 3.5rem;
}

.mt-9 {
  margin-top: 4rem;
}

.mt-10 {
  margin-top: 4.5rem;
}

/* Margin bottom */
.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 2rem;
}

.mb-6 {
  margin-bottom: 2.5rem;
}

.mb-7 {
  margin-bottom: 3rem;
}

.mb-8 {
  margin-bottom: 3.5rem;
}

.mb-9 {
  margin-bottom: 4rem;
}

.mb-10 {
  margin-bottom: 4.5rem;
}

/* Margin top and bottom */
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-5 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-6 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-7 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-8 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.my-9 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-10 {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}

/* Padding */
.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 1rem;
}

.p-4 {
  padding: 1.5rem;
}

.p-5 {
  padding: 2rem;
}

.p-6 {
  padding: 2.5rem;
}

.p-7 {
  padding: 3rem;
}

.p-8 {
  padding: 3.5rem;
}

.p-9 {
  padding: 4rem;
}

.p-10 {
  padding: 4.5rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-3 {
  padding-left: 1rem;
}

.pl-4 {
  padding-left: 1.5rem;
}

.pl-5 {
  padding-left: 2rem;
}

.pl-6 {
  padding-left: 2.5rem;
}

.pl-7 {
  padding-left: 3rem;
}

.pl-8 {
  padding-left: 3.5rem;
}

.pl-9 {
  padding-left: 4rem;
}

.pl-10 {
  padding-left: 4.5rem;
}

/* Padding right */
.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pr-3 {
  padding-right: 1rem;
}

.pr-4 {
  padding-right: 1.5rem;
}

.pr-5 {
  padding-right: 2rem;
}

.pr-6 {
  padding-right: 2.5rem;
}

.pr-7 {
  padding-right: 3rem;
}

.pr-8 {
  padding-right: 3.5rem;
}

.pr-9 {
  padding-right: 4rem;
}

.pr-10 {
  padding-right: 4.5rem;
}


/* Padding left and right */
.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-4 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-5 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-6 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.px-7 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-8 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.px-9 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.px-10 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}

/* Padding top */
.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 2.5rem !important;
}

.pt-7 {
  padding-top: 3rem !important;
}

.pt-8 {
  padding-top: 3.5rem !important;
}

.pt-9 {
  padding-top: 4rem !important;
}

.pt-10 {
  padding-top: 4.5rem !important;
}

/* Padding bottom */
.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem;
}

.pb-6 {
  padding-bottom: 2.5rem !important;
}

.pb-7 {
  padding-bottom: 3rem !important;
}

.pb-8 {
  padding-bottom: 3.5rem !important;
}

.pb-9 {
  padding-bottom: 4rem !important;
}

.pb-10 {
  padding-bottom: 4.5rem !important;
}


/* Padding top and bottom */
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-7 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-8 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-9 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-10 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

/* Grid */
.gap-0 {
  margin-left: 0;
  margin-right: 0;
}

.gap-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.gap-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.gap-3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.gap-4 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.gap-5 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.gap-6 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.gap-7 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.gap-8 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

.gap-9 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.gap-10 {
  margin-left: 4.5rem;
  margin-right: 4.5rem;
}

.danger {
  color: red !important;
}

.warning {
  color: orange !important;
}

.bold {
  font-weight: bold !important;
}

.blinker {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

