@mixin gridMixin {
  display: grid;
}

@mixin flexMixin {
  display: flex;
}

.grid {
  @include gridMixin();
}

.flex {
  @include flexMixin();
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.space-evenly {
  justify-content: space-evenly;
}

.center {
  justify-content: center;
}

.start {
  justify-content: start;
}

.end {
  justify-content: end;
}

.align-start {
  align-items: start;
}

.align-end {
  align-items: end;
}

.align-center {
  align-items: center;
}

.align-strech {
  align-items: stretch;
}

.wrap {
  flex-wrap: wrap;
}

.serial-box {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  width: 36px;
  height: 36px;
  background: #f0f1f3;
  border-radius: 8px;
  margin: 12px;
}

.regular-content {
  display: block;
}

.print-content {
  display: none;
}

@media print {
  .regular-content {
    display: none;
  }

  .print-content {
    display: block;
  }
}
